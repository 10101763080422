const LandingPageFS = `
  float random(in vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
}

void main() {
    vec2 st = gl_FragCoord.xy/iResolution.xy;
    float scale = 50.0;
    vec2 pos = vec2(st.x / 10.0, st.y) * scale;
    float direction = random(vec2(0.0, floor(pos.y))) * 2.0 - 1.0;
    float time = iTime + 2048.0;
    pos.x += direction * time *0.2 + 1024.0;
    
    vec2 rect = floor(pos);
    float rand = random(vec2(rect)) * 2.0 - 1.0;
    if (rand < 0.75) {
        rand = 0.0;
    }
    else {
        rand = 1.0;
    }
    float half_scale = scale * 0.5;
    float bar_height = 0.2 * scale;
    if (rect.y > half_scale - bar_height && rect.y < half_scale + bar_height) {
        rand = 0.0;
    }
    vec3 color = mix(vec3(0.149, 0.149, 0.149), palette_color, rand);
    
    gl_FragColor = vec4(color, 1.0);
}
`;

export default LandingPageFS;