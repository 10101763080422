import PALETTE from "../Constants";
import "./2_technology.css"
import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
const TechnogyPage = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let styles = {
        background: PALETTE.secondary
    }
    let text_styles = {
        color: PALETTE.primary,
        borderBottom: "15px solid" + PALETTE.primary
    }
    return <div className="section fp-auto-height" style={styles}>
        <div className="sections_holder">
            <div className="line-paragraph" style={{
                borderLeft: "10px solid" + PALETTE.primary,
                color: PALETTE.primary,
                marginTop: "100px",
                //borderRight: "#fdd835 10px solid"
            }}>
                <h2>Skills</h2>
                <p>
                    I have a keen interest in the domain of <b>computer vision and image processing</b>, dedicating myself to continuous advancement within this field. My unwavering commitment encompasses both conventional methodologies and cutting-edge <b>machine learning techniques</b>.

                    I also partially focus on <b>computer graphics and data processing</b>. I am comfortable with developing web or desktop applications using the C# language, utilizing technologies such as ASP.NET and WPF.

                </p>
            </div>
            <div style={text_styles} className="technology_section">
                <h1>C / C++</h1>
                <p><b>OpenCV</b></p>
                <p>OpenGL rendering</p>
                <p>QT</p>
                <p>Gmae development</p>
            </div>
            <div style={text_styles} className="technology_section">
                <h1>Python</h1>
                <p>OpenCV, skimage,...</p>
                <p>PyTorch</p>
                <p>Matplotlib</p>
                <p>Pandas</p>
            </div>
            <div style={text_styles} className="technology_section">
                <h1>C#</h1>
                <p>.NET 6.0</p>
                <p>ASP.NET a WPF</p>
                <p>Entity Framework</p>
                <p>Unity</p>
            </div>
            <div style={text_styles} className="technology_section">
                <h1>OpenGL</h1>
                <p>Shaders</p>
                <p>GPU akceleration</p>
                <p>Modern rendering <br /> techniques</p>
            </div>

        </div>
    </div >
}

export default TechnogyPage;