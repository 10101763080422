import { PALLETE_CONTEXT } from "../Constants";
import ShadertoyReact from "shadertoy-react";
import LandingPageFS from "../shaders/1_landing";
import "./1_landing.css"
import { useContext, useEffect, useState } from "react";

const LandingPage = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);

    const SMALL_WIDTH = 1200;
    let [small, setSmall] = useState(window.innerWidth < 1200);
    window.addEventListener('resize', function () {
        setSmall(window.innerWidth < 1200)
    });
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let styles = {
        background: PALETTE.secondary,
        "--primary": PALETTE.primary,
        "--secondary": PALETTE.secondary,
    }
    let text_styles = {
        color: PALETTE.primary,
        fontSize: small ? "50px" : "70px",
        borderTop: !isMobile ? "None" : "solid 10px " + PALETTE.primary,
        borderBottom: !isMobile ? "None" : "solid 10px " + PALETTE.primary,
        paddingBottom: !isMobile ? "None" : "20px",
        paddingTop: !isMobile ? "None" : "20px"
    }
    let link_styles = {
        fontSize: "2.1vw",
        textDecoration: "none",
        margin: "10px",
        color: PALETTE.primary,
    }
    if (small) link_styles.display = "none";
    let logo_style = {
        width: "auto",
        position: "absolute",
        top: "50%",
        left: small ? "50%" : "5%",
        transform: small ? "translate(-50%,-50%)" : "translate(0%,-50%)"
    }
    return <div className="section" style={styles}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: "100%", margin: "0px", padding: "0px" }}>
                {!isMobile ? <ShadertoyReact
                    fs={LandingPageFS}
                    style={{ width: "100%", height: "100%" }}
                    uniforms={{
                        palette_color: { type: "3f", value: PALETTE.primary_vec3 }
                    }
                    }
                /> : ""}
            </div>
            <div style={logo_style}>
                <h1 style={text_styles}>JAN&nbsp;ŠKVAŘIL</h1>
            </div>
            <div style={{
                position: "absolute", top: "50%", right: "5%", transform: "translate(0%,-50%)",
            }}>
                <a className="landing_link" style={link_styles} href="#technology">Skills</a>
                <a className="landing_link" style={link_styles} href="#study">Projects</a>
                <a className="landing_link" style={link_styles} href="#hobby">Hobby</a>
                <a className="landing_link" style={link_styles} href="#contact">Contact</a>
            </div>
        </div>
    </div>
}

export default LandingPage;