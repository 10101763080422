import PALETTE from "../Constants";
import "./4_hobby.css"
import Game from "./game/game";
import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
const HobbyPage = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let styles = {
        background: PALETTE.secondary,
        width: "100%",
        height: "100%",
        minHeight: !isMobile ? "100vh" : "auto",
    }
    return <div className="section fp-auto-height" >
        <div style={styles} className="hobby_wrapper">
            <div >
                <div className="line-paragraph" style={{
                    borderLeft: "10px solid" + PALETTE.primary,
                    color: PALETTE.primary,
                    marginTop: "30px",
                    marginBottom: "50px",
                    width: "auto",
                    fontSize: "25px"
                }}>
                    <h2>Game Development</h2>
                    <p>
                        In my free time, I am passionate about game development. I enjoy working on small game projects, participating in game jams, and studying various aspects of game development. Through these endeavors, I gain valuable experiences not only in computer graphics but also in areas such as network application development. Additionally, I am gradually acquiring skills in modeling using tools like Blender, game engine Unity, and many others.
                    </p>
                </div>
            </div>
            <div>
                {!isMobile ? <Game /> : ""}
            </div>
        </div>

    </div >
}


export default HobbyPage;