import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
import "./palette_picker.css"
const Box = (props) => {
    let box_styles = {

        backgroundColor: props.color.primary,

    }
    return <div className="palette_box" onClick={() => { props.onClick(props.color) }} style={box_styles}></div>
}

const PalettePicker = () => {

    let style = {

        zIndex: "10",
        boxShadow: "0px 0px 21px 7px rgba(0,0,0,0.4)"
    }
    let paletts = [
        {
            "primary": "#fdd835",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.992, 0.847, 0.208]
        },
        {
            "primary": "#ff9800",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [1., 0.596, 0.]
        },
        {
            "primary": "#e57373",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.898, 0.451, 0.451]
        },
        {
            "primary": "#cfd8dc",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.812, 0.847, 0.863]
        },
        {
            "primary": "#80cbc4",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.898, 0.451, 0.451]
        },
        {
            "primary": "#00acc1",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0., 0.675, 0.757]
        },
        {
            "primary": "#cddc39",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.804, 0.863, 0.224]
        },
        {
            "primary": "#689f38",
            "secondary": "rgb(38,38,38)",
            "primary_vec3": [0.408, 0.624, 0.22]
        },

    ]
    const handleClick = (color) => {
        setPalette(color);
        localStorage.setItem('color', JSON.stringify(color));
    }
    let boxes = [];
    for (let i = 0; i < paletts.length; i++) {
        boxes.push(<Box onClick={handleClick} key={i} color={paletts[i]} />)
    }
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let myStorage = window.localStorage;
    let prev = myStorage.getItem("color");
    if (prev != null && JSON.stringify(PALETTE) != prev) {
        setPalette(JSON.parse(prev));
    }
    return <div style={style} className="palette_wraper" >
        {boxes}

    </div>
}


export default PalettePicker;