import PALETTE from "../Constants";
import "./3_study.css";
import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
const CellSegmentationProject = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let styles = {
        background: PALETTE.secondary,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "50px",
    }
    let card_styles = {
        color: PALETTE.primary
    }
    return <div className="section fp-auto-height">
        <div style={styles} className="study_wrapper">
            <h1 style={{ backgroundColor: PALETTE.primary, color: PALETTE.secondary }}>Mouse cells instance segmentation</h1>
            <div className="study_card_wrapper">
                <div className="line-paragraph" style={{
                    borderLeft: "10px solid" + PALETTE.primary,
                    color: PALETTE.primary,
                    marginTop: "30px",
                    marginBottom: "30px",

                }}>
                    <p>
                        Web <a className="web_linka" target="_blank" href="http://celltrackingchallenge.net/">celltrackingchallenge.net</a> provides several datasets in which the task is to perform <b>instance segmentation</b> on a sequence of cell images.
                    </p>
                    <p>
                        I have conducted a series of experiments focused on the utilization of the <b>U-Net</b> architecture and its variant, <b>LSTM U-Net</b>. Results can be found on my <a className="web_linka" target="_blank" href="https://github.com/JanSkvaril/CellSegmentation_LSTMUnet">github</a>.
                    </p>
                </div>
                <img loading="lazy" className="example_img" src={require("../imgs/unet_fulll.gif")} alt="visualization of the results" />
                <img loading="lazy" className="example_img" src={require("../imgs/cop_res.gif")} alt="visualization of the results" />
            </div>
        </div>
    </div>
}

export default CellSegmentationProject;