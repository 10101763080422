import PALETTE from "../Constants";
import "./5_contact.css";
import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
const ContactPage = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let styles = {
        background: PALETTE.secondary,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "100px",
        paddingTop: "100px",
        "--primary": PALETTE.primary,
        "--secondary": PALETTE.secondary,
    }
    return <div className="section fp-auto-height">
        <div style={styles}>
            <div className="contact_wrapper" style={{
                // width: "100%",

            }}>
                <div style={{
                    float: "left",

                    textAlign: "right",

                }}>
                    <a className="end_link" href="#up">Up</a>
                    <a className="end_link" href="#technology" >Skills</a>
                    <a className="end_link" href="#study">Projects</a>
                    <a className="end_link" href="#hobby">Hobby</a>
                </div>
                <div className="contact_text" style={{

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "10px",
                    paddingLeft: "30px",
                    marginRight: "10%",
                    paddingRight: "20px",
                    textAlign: "left",
                    color: PALETTE.primary,
                    borderLeft: "10px solid " + PALETTE.primary,

                    fontWeight: "lighter"
                    //borderRight: "#fdd835 10px solid"
                }}>
                    <h2>Contact</h2>
                    <p><br />CV: <a style={{ color: PALETTE.primary }} href={require("../misc/skvaril_cv.pdf")}>PDF</a><br /><br />
                        Personal email: <br /><b>jan.skvaril@gabros.cz</b></p>
                </div>
            </div>
        </div>
    </div >
}

export default ContactPage;