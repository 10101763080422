import { useEffect, useState } from "react";
import Sketch from "react-p5";
import { PALLETE_CONTEXT } from "../../Constants";
import { useContext } from "react";
const rect_width = 200;
const rect_height = 20;

let max_speed = 5;
let frame_count = 0;
let error_counter = 0;
let rects = [];
let paused = true;
let level = 0;
class Rect {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.speed = 0;
    }
    Reset(p5, screenWidth, screenHeight) {
        this.x = p5.random(screenWidth, screenWidth * 1.5);
        this.y = Math.floor(p5.random(0, screenHeight / 20)) * rect_height;
        this.speed = p5.random(3, max_speed);
    }
    ForceReset() {
        this.x = -10000;
    }
    Update(p5, screenWidth, screenHeight) {
        this.x -= this.speed;
        if (this.x < -rect_width * 1) {
            this.Reset(p5, screenWidth, screenHeight);
        }
    }
    Draw(p5) {
        p5.rect(this.x, this.y, rect_width, rect_height);
    }
    IsCollidion(ox, oy, ow, oh) {
        return (this.x < ox + ow &&
            this.x + rect_width > ox &&
            this.y < oy + oh &&
            rect_height + this.y > oy);
    }

}

const Game = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let [screenWidth, setWidth] = useState(window.innerWidth * 0.9);
    let [screenHeight, setHeight] = useState(window.innerHeight * 0.5);

    window.addEventListener('resize', function () {
        setWidth(window.innerWidth * 0.9);
        setHeight(window.innerHeight * 0.5);
    });
    max_speed = 5;
    let x = 50;
    let y = screenHeight / 2;

    const edge = rect_height;
    const width = 70;
    const diff_timer = 100;

    useEffect(() => {
        // forceReset();
    }, [PALETTE])
    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(screenWidth, screenHeight).parent(canvasParentRef);
        for (let i = 0; i < 20; i++) {
            rects.push(new Rect());
            rects[i].Reset(p5, screenWidth, screenHeight);
        }
    };
    const togllePause = (p5) => {
        if (p5.mouseX > 0 && p5.mouseX < screenWidth && p5.mouseY > 0 && p5.mouseY < screenHeight)
            paused = !paused;
    }
    const reset = (p5) => {
        p5.resizeCanvas(screenWidth, screenHeight);
        rects = [];
        level = 0;
        max_speed = 5;
        for (let i = 0; i < 10; i++) {
            rects.push(new Rect());
            rects[i].Reset(p5, screenWidth, screenHeight);
        }
    }
    const forceReset = () => {
        rects = [];
        level = 0;
        max_speed = 5;
        for (let i = 0; i < 10; i++) {
            rects.push(new Rect());
            rects[i].ForceReset();
        }
    }

    const draw = (p5) => {
        frame_count++;
        if (!paused && frame_count % diff_timer == 0) {
            //rects.push(new Rect());
            //rects[rects.length - 1].Reset(p5);
            if (max_speed <= 25) {
                max_speed++;
            }
            else {
                rects.push(new Rect());
                rects[rects.length - 1].Reset(p5);
            }
            level++;
        }
        p5.noStroke();
        p5.background(35, 35, 35);
        if (error_counter > 0) {
            error_counter--;
            p5.background(20, 20, 20);
        }
        let col = false;
        for (let i = 0; i < rects.length; i++) {
            if (!paused) {
                rects[i].Update(p5, screenWidth, screenHeight);
                if (rects[i].IsCollidion(x, y, rect_width / 2, rect_height)) {
                    col = true;
                    rects[i].Reset(p5, screenWidth, screenHeight);
                }
            }
            p5.fill(PALETTE.primary);
            rects[i].Draw(p5);

        }
        if (col) {
            if (max_speed > 5) max_speed--;
            level--;
            error_counter = 10;
        }
        else {
            p5.fill(0, 151, 167);

        }
        p5.rect(x, y, rect_width / 2, rect_height);
        //   x = p5.mouseX - width / 2;
        if (!paused) {
            y = p5.mouseY - width / 2;
        }
        // clamp edges
        if (y < edge) y = edge;
        else if (y > screenHeight - edge * 2) y = screenHeight - edge * 2;
        // if (x < edge) x = edge;
        // else if (x > screenWidth - edge * 2) x = screenWidth - edge * 2;
        p5.fill(PALETTE.primary);
        p5.textSize(32);


        if (paused) {

            p5.text("Paused", 30, 60);
            p5.textSize(64);
            p5.text("Click to start", screenWidth / 2 - 150, screenHeight / 2);
        }
        else {
            p5.text("Score: " + level.toString(), 30, 60);
        }

    };
    return <Sketch setup={setup} draw={draw} windowResized={reset} mouseClicked={togllePause} />;
}


export default Game;