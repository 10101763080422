import logo from './logo.svg';
import './App.css';


import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { PALLETE_CONTEXT } from './Constants';
import LandingPage from './pages/1_landing';
import TechnogyPage from './pages/2_technology';
import StudyPage from './pages/3_study';
import HobbyPage from './pages/4_hobby';
import ContactPage from './pages/5_contact';
import PalettePicker from "./pages/palette_picker"
import CellSegmentationProject from './pages/3_1_cell_segmentation';
function App() {
  const anchors = ["up", "skills", "projects","project1", "hobby" , "contact"];
  const [PALETTE, setPalette] = useState({
    "primary":"#fdd835",
    "secondary":"rgb(38,38,38)",
    "primary_vec3": [0.992,0.847,0.208]
});
  return (  
  <PALLETE_CONTEXT.Provider value={{ PALETTE, setPalette }}>
    <PalettePicker/>
  <ReactFullpage
   navigation
   navigationTooltips={anchors}
   navigationPosition= 'left'
    anchors={anchors}
    scrollingSpeed = {1000} /* Options here */
    responsiveSlides={true} 
    responsiveWidth={1200}
    responsiveHeight={800}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
         <LandingPage/>
         <TechnogyPage />
         <StudyPage/>
         <CellSegmentationProject/>
         <HobbyPage/>
         <ContactPage/>
        </ReactFullpage.Wrapper>
      );
    }}
  />
    </PALLETE_CONTEXT.Provider>
  );
}

export default App;
