import PALETTE from "../Constants";
import "./3_study.css";
import { PALLETE_CONTEXT } from "../Constants";
import { useContext } from "react";
const StudyPage = () => {
    const { PALETTE, setPalette } = useContext(PALLETE_CONTEXT);
    let styles = {
        background: PALETTE.secondary,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "50px",
    }
    let card_styles = {
        color: PALETTE.primary
    }
    return <div className="section fp-auto-height">
        <div style={styles} className="study_wrapper">
            <h1 style={{ backgroundColor: PALETTE.primary, color: PALETTE.secondary }}>Some intresting projects</h1>
            <div className="study_card_wrapper">
                <div className="line-paragraph" style={{
                    borderLeft: "10px solid" + PALETTE.primary,
                    color: PALETTE.primary,
                    marginTop: "30px",
                    marginBottom: "30px",

                }}>
                    <p>
                        After completing my bachelor's studies at the Faculty of Information Technology at Brno University of Technology (VUT FIT), I embarked on a follow-up master's program at Masaryk University, specializing in <b>image analysis and processing</b>.                    </p>
                    {/* 
                    <p>
                        These projects were created either as part of my studies or in my free time. Projects developed during my employment are not mentioned.
                    </p> */}
                </div>
                <div style={card_styles} className="study_card">
                    <div className="lang_spec">C# + React.js</div>
                    <a href="https://github.com/zeryk24/CarService.git">Github</a>
                    <h2>Information system for an auto repair shop</h2><br />
                    <p>Complete information system including API following the MVC architecture, database, and web application.
                        The system also included a mobile and desktop application.
                    </p>
                </div>
                <div style={card_styles} className="study_card study_card_bp">
                    <div className="lang_spec">C++ OpenGL</div>
                    <h2>Bachelor's thesis</h2>
                    <p>
                        In my bachelor's thesis, I focus on creating a tool for simulating particle effects using light fields, which enable the display of 3D objects without relying solely on pre-rendered images.
                    </p>
                </div>
                <div style={card_styles} className="study_card">
                    <div className="lang_spec">Python</div>
                    <h2>Data Processing and Visualization</h2>
                    <p>Processing and visualizing a large dataset of traffic accidents recorded by the Czech Republic Police using libraries such as numpy, pandas, geopandas, matplotlib, and seaborn.</p>
                </div>

                <div style={card_styles} className="study_card">
                    <div className="lang_spec">C++</div>
                    <a href={require("../misc/dokumentace.pdf")}>PDF</a>
                    <h2>Simulation Study</h2>
                    <p>Preparation of a simulation study and creation of a simulation model focusing on the impact of various measures on a state's inflation during a crisis (COVID-19 epidemic).</p>                </div>

                <div style={card_styles} className="study_card">
                    <a href="http://www.stud.fit.vutbr.cz/~xskvar09/gamejam/build/">Result</a>
                    <div className="lang_spec">C# Unity</div>
                    <h2>GameJam</h2>
                    <p>A two-day GameJam with the goal of creating a game using the Unity engine on the theme of "Random". We decided to develop a simple card game, see the results in the link.</p>
                </div>
                <div style={card_styles} className="study_card">
                    <div className="lang_spec">C++</div>
                    <h2>GPU Pipeline Simulation</h2>
                    <p>Implementation of a simple GPU pipeline simulation, involving working with buffers and uniform variables, triangle assembly, rasterization, etc.</p>
                </div>
            </div>
        </div>

    </div>
}

export default StudyPage;